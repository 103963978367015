


















































































































import DateUtil from 'global-ui/packages/utils/DateUtil';
import { FlowOperationList } from '@/models/flow/FlowConst';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import EleUpload from '@/components/form-design/elements/ele-upload.vue';
import { getImUrl } from '@/api/system/AssistApi';
import { getUserSingle, updateRemark } from '@/api/system/UserApi';
import { Message } from 'element-ui';
@Component({
  name: 'FlowLogs',
  components: { EleUpload }
})
export default class ProcessSubmitWords extends Vue {
  // 接受父组件的节点实例ID
  @Prop({
    required: false,
    default: () => []
  })
  data!: any[];
  logs: any[] = [];
  @Prop({ default: false }) allowUpdate: boolean;
  operationList: Record<string, string>[] = FlowOperationList;
  defaultNodeColor: string = '#409EFF';
  excutedNodeColor: string = '#33aaaa';
  arrivingNodeColor: string = '#dd8877';

  private showImDlg: boolean = false;
  private imUrl: string = '';

  get _activedLogNode() {
    return this.$store.getters.activedLogNode;
  }

  get _currentTask() {
    return this.$store.getters.currentTask;
  }

  get _processEnd() {
    if (this.$store.getters.processInstance) {
      return this.$store.getters.processInstance.end;
    }
    return false;
  }

  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  created() {
    this.initData();
  }
  showInput(index: number, i: number) {
    this.logs.forEach((item: any) => {
      item.taskHistories.forEach((value: any) => {
        value['showInput'] = false;
      });
    });
    this.logs[index].taskHistories[i].showInput = true;
    this.$forceUpdate();
  }
  save(id: string, remark: string) {
    updateRemark({ id: id, userRemark: remark }).then((res: any) => {
      if (res.code == '1') {
        this.initData();
        this.logs.forEach((item: any) => {
          item.taskHistories.forEach((value: any) => {
            value['showInput'] = false;
          });
        });
        this.$forceUpdate();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  initData() {
    this.logs = this.buildLogs();
  }

  buildLogs() {
    if (this.data && this.data.length) {
      return this.data.map(logItem => {
        if (this._activedLogNode && this._activedLogNode.id == logItem.nodeId) {
          if (this._activedLogNode.properties.isExecuted) {
            logItem.color = this.excutedNodeColor;
            logItem.fontWeight = 700;
            logItem.fontColor = this.excutedNodeColor;
          } else if (this._activedLogNode.properties.isArriving) {
            logItem.color = this.arrivingNodeColor;
            logItem.fontWeight = 700;
            logItem.fontColor = this.arrivingNodeColor;
          }
          (document.getElementById(logItem.nodeId) as any).scrollIntoView({ behavior: 'smooth' });
        } else {
          logItem.color = this.defaultNodeColor;
          logItem.fontWeight = 500;
          logItem.fontColor = '#303133';
        }
        return logItem;
      });
    }
    return [];
  }

  /**
   * 格式化日期
   */
  getDatetime(date: Date) {
    if (date) {
      return DateUtil.format(new Date(date), 'yyyy-MM-dd hh:mm:ss');
    }
    return '';
  }

  /**
   * 格式化提交类型
   */
  getSubmitTypeName(logItem: any): string {
    let result: any = '';
    this.operationList.forEach((item: any) => {
      if (item.id == logItem.submitType) {
        result = this.$t(item.name);
      }
    });
    return result;
  }

  openHistoryTask(nodeItem): void {
    let routeUrl = this.$router.resolve({
      path: '/flow/done',
      query: { id: nodeItem.taskHistories[0].id }
    });
    window.open(routeUrl.href, '_blank');
  }

  executeScript(userId) {
    let windowObj: any = window;
    if (windowObj.EventBridge) {
      getUserSingle(userId).then((res: any) => {
        if (res.code == '1') {
          let account = res.data.account;
          windowObj.EventBridge.emitWebEvent({
            type: 'activeButton',
            data: { button: 'Chat', sendMsg: { action: 'gotoUser', user: account } }
          });
        } else {
          this.toImPage();
        }
      });
    } else {
      this.toImPage();
    }
  }

  private toImPage() {
    getImUrl()
      .then((res: any) => {
        if (res.code == '1') {
          this.imUrl = res.data;
          this.showImDlg = true;
        } else {
        }
      })
      .catch(e => {});
  }

  @Watch('data')
  dataWatcher(newVal, oldVal) {
    this.logs = this.buildLogs();
    this.logs.forEach((item: any) => {
      item.taskHistories.forEach((value: any) => {
        value['showInput'] = false;
      });
    });
  }

  @Watch('_activedLogNode')
  activedLogNodeWatcher(newVal, oldVal) {
    if (newVal.id != oldVal.id) {
      this.logs = this.buildLogs();
    }
  }
}
